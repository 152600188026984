import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../AuthContext';
import ContentCard from './ContentCard';
import TagFilter from './TagFilter';
import { API_BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';

function ContentFeed() {
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const { user, checkAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContents = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/posts`, {
          credentials: 'include',
        });
        if (!response.ok) {
          if (response.status === 401) {
            checkAuth();
          } else {
            throw new Error('Failed to fetch contents');
          }
        } else {
          const data = await response.json();
          setContents(data);
          setError(null);
        }
      } catch (err) {
        console.error('Error fetching posts:', err);
        setError(err.message || 'An error occurred while fetching posts');
      } finally {
        setLoading(false);
      }
    };

    fetchContents();
  }, [user, checkAuth, navigate]);

  const allTags = useMemo(() => {
    const tagSet = new Set();
    contents.forEach((content) => {
      content.tags.forEach((tag) => tagSet.add(tag));
    });
    return Array.from(tagSet);
  }, [contents]);

  const filteredContents = useMemo(() => {
    if (selectedTags.length === 0) return contents;
    return contents.filter((content) =>
      selectedTags.some((tag) => content.tags.includes(tag))
    );
  }, [contents, selectedTags]);

  const handleTagToggle = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag)
        : [...prevTags, tag]
    );
  };

  const handlePostUpdate = (updatedPost) => {
    setContents((prevContents) =>
      prevContents.map((content) =>
        content.id === updatedPost.id ? updatedPost : content
      )
    );
  };

  if (loading) return <div className="text-center py-4"><span className="loading loading-spinner loading-lg"></span></div>;
  if (error) return <div className="alert alert-error">{error}</div>;

  return (
    <div className="flex flex-col items-center">
      <div className="w-full max-w-2xl">
        <TagFilter
          tags={allTags}
          selectedTags={selectedTags}
          onTagToggle={handleTagToggle}
        />
        <div className="space-y-4">
          {filteredContents.map(content => (
            <ContentCard key={content.id} content={content} onUpdate={handlePostUpdate} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContentFeed;
