import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { FaSun, FaMoon, FaBars } from 'react-icons/fa';

function Header({ theme, toggleTheme }) {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleLogout = async () => {
    const success = await logout();
    if (success) {
      navigate('/login');
    }
    closeMenu();
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const menuItems = (
    <>
      <li><Link to="/how-to-use" onClick={closeMenu}>How to Use</Link></li>
      {user ? (
        <>
          <li><Link to="/follow" onClick={closeMenu}>Follow User</Link></li>
          <li><Link to="/follow-lists" onClick={closeMenu}>Follow Lists</Link></li>
          <li><Link to="/profile" onClick={closeMenu}>Profile</Link></li>
          <li>
            <button onClick={handleLogout} className="btn btn-error btn-sm">
              Logout
            </button>
          </li>
        </>
      ) : (
        <>
          <li><Link to="/login" onClick={closeMenu}>Login</Link></li>
          <li><Link to="/register" onClick={closeMenu}>Register</Link></li>
        </>
      )}
      <li>
        <button onClick={() => { toggleTheme(); closeMenu(); }} className="btn btn-ghost btn-circle">
          {theme === 'light' ? <FaMoon /> : <FaSun />}
        </button>
      </li>
    </>
  );

  return (
    <header className="bg-primary text-primary-content shadow-lg">
      <div className="navbar container mx-auto">
        <div className="flex-1">
          <Link to="/" className="btn btn-ghost text-xl">WallShare</Link>
        </div>
        <div className="flex-none">
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost lg:hidden" onClick={toggleMenu}>
              <FaBars />
            </label>
            <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
              {menuItems}
            </ul>
          </div>
          <ul className="menu menu-horizontal px-1 hidden lg:flex">
            {menuItems}
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
